const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <div className="header-content">
          <h2 className="title glitch layers" data-text="OMNYX"><span>OMNYX</span></h2>
          <p className="subtitle">Your trusted <span className="highlight">partner</span> in cybersecurity solutions</p>
        </div>
        
        <div className="cyber-grid">
          {/* Animated background grid */}
          <div className="grid-overlay"></div>
        </div>
      </header>

      <section className="advanced-capabilities">
        <div className="container">
          <h2 className="section-title">Advanced AI Solutions</h2>
          <p className="section-subtitle">Next-Generation AI Vision Systems for Commercial & Defence Applications</p>

          <div className="capabilities-grid">
            <div className="capability-card">
              <div className="capability-icon"></div>
              <h3>AI Vision Systems</h3>
              <p>Advanced computer vision algorithms enabling real-time detection and identification for multiple use-cases.</p>
              <ul className="capability-features">
                <li>Multi-spectrum image processing</li>
                <li>Real-time object detection</li>
                <li>Autonomous tracking and recognition</li>
                <li>Environmental assessment algorithms</li>
              </ul>
            </div>

            <div className="capability-card">
              <div className="capability-icon uas-isr"></div>
              <h3>UAS Capabilities</h3>
              <p>Unmanned vehicle capabilities enhanced by cutting-edge AI technology.</p>
              <ul className="capability-features">
                <li>Advanced sensor fusion</li>
                <li>Integrated data analysis</li>
                <li>Pattern recognition systems</li>
                <li>Secure communications infrastructure</li>
              </ul>
            </div>

            <div className="capability-card">
              <div className="capability-icon defense"></div>
              <h3>Defense & Security</h3>
              <p>Comprehensive security solutions for critical infrastructure and security.</p>
              <ul className="capability-features">
                <li>Drone detection systems</li>
                <li>Perimeter security solutions</li>
                <li>Threat assessment systems</li>
                <li>Emergency response protocols</li>
              </ul>
            </div>
          </div>

          <div className="capabilities-showcase">
            <div className="showcase-content">
              <h3>Next-gen AI Integration</h3>
              <p>Our systems leverage state-of-the-art artificial intelligence to provide:</p>
              <ul>
                <li>High accuracy in trained object identification</li>
                <li>Realtime response times</li>
                <li>24/7 autonomous operation capability</li>
                <li>Military-grade encryption standards</li>
              </ul>
            </div>
            <div className="showcase-visual">
              <div className="tech-matrix">
                <div className="target-label">
                  <span className="label-text">97% - TARGET IDENTIFIED</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* AI Analytics section */}
      <section className="analytics-section">
        <div className="container">
          <h2 className="section-title">AI-Powered Analytics</h2>
          <p className="section-subtitle">Transform raw data into actionable reports</p>

          <div className="analytics-grid">
            <div className="analytics-card">
              <div className="analytics-visual predictive"></div>
              <h3>Predictive Analytics</h3>
              <p>Advanced machine learning algorithms to forecast potential security threats and system vulnerabilities.</p>
              <ul className="analytics-features">
                <li>Threat pattern recognition</li>
                <li>Behavioral anomaly detection</li>
                <li>Risk probability modeling</li>
                <li>Proactive defense planning</li>
              </ul>
            </div>

            <div className="analytics-card">
              <div className="analytics-visual realtime"></div>
              <h3>Real-Time Processing</h3>
              <p>Instant analysis of large disparate data streams.</p>
              <ul className="analytics-features">
                <li>Live data processing</li>
                <li>Neural network analysis</li>
                <li>Automated response triggers</li>
                <li>Performance optimization</li>
              </ul>
            </div>

            <div className="analytics-card">
              <div className="analytics-visual intelligence"></div>
              <h3>Intelligence Synthesis</h3>
              <p>Comprehensive data fusion from multiple sources to create actionable security insights.</p>
              <ul className="analytics-features">
                <li>Multi-source integration</li>
                <li>Pattern correlation</li>
                <li>Predictive modeling</li>
                <li>Strategic recommendations</li>
              </ul>
            </div>
          </div>

          <div className="analytics-dashboard">
            <div className="dashboard-preview">
              <div className="data-visualization">
                <div className="y-axis">
                  <div className="y-markers">
                    <span>100%</span>
                    <span>80%</span>
                    <span>60%</span>
                    <span>40%</span>
                    <span>20%</span>
                    <span>0%</span>
                  </div>
                </div>
                <div className="x-axis">
                  <div className="x-markers">
                    <span>0</span>
                    <span>0.5</span>
                    <span>1.0</span>
                    <span>1.5</span>
                    <span>2.0</span>
                    <span>2.5</span>
                    <span>3.0</span>
                  </div>
                </div>
                <div className="data-lines">
                  <div className="data-line line-1">
                    <svg width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 150 200">
                      <path d="M0,140 C25,100 50,130 75,90 S125,120 150,80" />
                    </svg>
                  </div>
                  <div className="data-line line-2">
                    <svg width="100%" height="100%" preserveAspectRatio="none" viewBox="0 0 150 200">
                      <path d="M0,160 C25,170 50,150 75,165 S125,140 150,155" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className="dashboard-content">
              <h3>Advanced Analytics Dashboards</h3>
              <p>Real-time visualization of performance metrics, threat analysis and realtime data feeds.</p>
            </div>
          </div>
        </div>
      </section>

    
      {/* Services section */}
      <section className="services-section">
        <div className="container">
          <h2 className="section-title">Cyber Security Solutions</h2>
          
          <div className="service-detailed">
            <div className="service-content">
              <h3>Advanced Penetration Testing</h3>
              <p className="service-description">
                Our comprehensive penetration testing combines automated scanning with expert human analysis to identify vulnerabilities before they can be exploited.
              </p>
              <ul className="service-features">
                <li>Advanced vulnerability scanning</li>
                <li>Threat modeling and analysis</li>
                <li>Exploit verification</li>
                <li>Comprehensive risk assessment</li>
              </ul>
            </div>
            <div className="service-visual">
              <div className="tech-hexagon"></div>
            </div>
          </div>

          <div className="service-detailed reverse">
            <div className="service-content">
              <h3>Next-Gen Cyber Security Services</h3>
              <p className="service-description">
                Comprehensive security solutions powered by artificial intelligence and machine learning, providing real-time threat detection and response.
              </p>
              <ul className="service-features">
                <li>AI-powered threat detection</li>
                <li>Real-time security monitoring</li>
                <li>Behavioral analysis</li>
                <li>Automated incident response</li>
              </ul>
            </div>
            <div className="service-visual">
              <div className="tech-sphere"></div>
            </div>
          </div>

          <div className="service-detailed">
            <div className="service-content">
              <h3>Virtual CISO (vCISO) Services</h3>
              <p className="service-description">
                Strategic security leadership enhanced by AI-driven insights, providing enterprise-level security guidance for organizations of all sizes.
              </p>
              <ul className="service-features">
                <li>AI-assisted risk assessment</li>
                <li>Security roadmap development</li>
                <li>Compliance management</li>
                <li>Security awareness training</li>
              </ul>
            </div>
            <div className="service-visual">
              <div className="tech-wave"></div>
        </div>
        </div>
        </div>
      </section>

      {/* Enhanced contact section */}
      <div className="contact-section">
        <div className="contact-content">
          <h2>Ready to Transform Your Security Posture?</h2>
          <p className="contact-description">
            Join industry leaders who trust OMNYX for next-generation cybersecurity solutions
          </p>
          <a href="mailto:contact@omnyx.co" className="contact-btn">Schedule a Consultation</a>
        </div>
      </div>

      <footer className="App-footer">
        <p>&copy; 2025 Omnyx Global. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;